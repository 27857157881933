<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { getAction, putAction } from '@/command/netTool'
import { refundIsList, orderSourceList, orderStateList2 } from '@/utils/textFile'
import moment from 'moment'
export default {
  name: 'refund',
  data() {
    return {
      ...api.command.getState(),
      refundList: refundIsList,
      refundStatue: 1,
      orderRefundId: null,
      orderItemId: null,
      refundId: null,
    }
  },
  activated() {
    let { id } = this.$route.query
    if (id) {
      this.getDataInit(id)
    }
  },
  mounted() {
    let { id } = this.$route.query
    this.getDataInit(id)
  },
  methods: {
    getDataInit(id = '') {
      let data = {
        url: '/order/refund/page',
        current: 1,
      }
      if (id) {
        data.params = {
          id,
        }
      }
      api.command.getList.call(this, data)
    },
    getHeader() {
      return [
        {
          name: '单号',
          type: 'input',
          key: 'orderSn',
          placeholder: '请输入单号',
        },
        {
          name: '店铺名称',
          type: 'input',
          key: 'shopName',
        },
        {
          name: '现价',
          type: 'row',
          children: [
            {
              type: 'input',
              cols: 11,
              label: '最低现价',
              key: 'minPrice',
            },
            {
              type: 'text',
              value: '至',
              cols: 2,
              align: 'center',
            },
            {
              type: 'input',
              cols: 11,
              label: '最高现价',
              key: 'maxPrice',
            },
          ],
        },
      ]
    },
    getColumns() {
      return [
        {
          dataIndex: 'orderSn',
          title: '单号',
          isId: true,
          align: 'left',
          sorter: (a, b) => a.orderSn - b.orderSn,
        },
        {
          dataIndex: 'productName',
          title: '出售商品',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <div class="product-order-item">
                  <img src={records.productPic} />
                  {records.formType === '13' ? (
                    <div>
                      {records.hotelName} {records.productName} {records.roomName}
                    </div>
                  ) : (
                    <div>
                      {records.productName.length > 45
                        ? records.productName.substring(0, 45) + '...'
                        : records.productName}
                    </div>
                  )}
                </div>
              </div>
            )
          },
        },
        {
          dataIndex: 'shopName',
          title: '店铺名称',
          align: 'left',
        },
        {
          dataIndex: 'productCount',
          title: '订单数量',
          align: 'left',
        },
        {
          dataIndex: 'payAmount',
          title: '实付金额',
          align: 'left',
          customRender: (text, records) => {
            return (
              <div>
                <span>{records.payAmount.toFixed(2)}</span>
                {/* records.status == '2' && records.returnAmount && <span>（{records.returnAmount.toFixed(2)} 已退）</span> */}
              </div>
            )
          },
        },
        {
          dataIndex: 'returnCount',
          title: '退款数量',
          align: 'left',
        },
        {
          dataIndex: 'returnAmount',
          title: '退款金额',
          align: 'left',
          customRender: (text, records) => {
            return <div>{records.returnAmount && <span>{records.returnAmount.toFixed(2)}</span>}</div>
          },
        },
        {
          dataIndex: 'formType',
          title: '订单来源',
          align: 'left',
          customRender: (text) => (orderSourceList.find((e) => e.value == text) || { text: '' }).text,
          filters: orderSourceList,
          filterMultiple: false,
          onExport: (text) => (orderSourceList.find((e) => e.value == text) || { text: '' }).text,
        },
        {
          dataIndex: 'status',
          title: '退款情况',
          type: 'badge',
          align: 'center',
          filters: this.refundList,
          render(data) {
            return {
              showDot: true,
              name: {
                1: '等待处理',
                2: '已退款',
                3: '已拒绝',
                5: '退款失败',
              }[data],
              color: data == 3 || data == 5 ? 'red' : data == 1 ? 'orange' : '#eee',
            }
          },
          onExport: (records) => {
            return {
              1: '等待处理',
              2: '已退款',
              3: '已拒绝',
              5: '退款失败',
            }[records]
          },
          filterMultiple: false,
        },

        {
          dataIndex: 'action',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                type: 'pop',
                popTitle: '确认同意退款吗?',
                name: '同意',
                display: records.status == 1 && !['1'].includes(records.formType) && records.thirdTicket !== '1',
                onClick: () => {
                  let params = {
                    refundId: records.id,
                    handleResult: '0',
                  }
                  let url = '/order/refund/ticketRefund'
                  if (['12'].includes(records.formType)) {
                    url = '/order/refund/roomRefund'
                  }
                  if (['14'].includes(records.formType)) {
                    url = '/order/refund/comboRefund'
                  }
                  putAction(url, {
                    ...params,
                  }).then((result) => {
                    if (result.code == 200) {
                      if (result.data != 5) {
                        this.$message.success('操作成功')
                      } else {
                        this.$message.error('操作失败')
                      }
                      this.getDataInit()
                    } else {
                      this.$message.error(result.msg)
                    }
                  })
                },
              },
              {
                name: '拒绝',
                display: records.status == 1 && !['1'].includes(records.formType) && records.thirdTicket !== '1',
                onClick: () => {
                  this.handlePropup(records)
                },
              },
              {
                name: '退款处理',
                display: records.status == 1 && records.formType == '1',
                onClick: () => {
                  this.onRowSelect(records, '退款处理')
                },
              },
              {
                name: '主动退款',
                display: records.status == 3 && records.formType == '1',
                onClick: () => {
                  this.onRowSelect(records, '主动退款')
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    handlePropup(records) {
      apiTool.showDrawer({
        title: '拒绝退款',
        width: '500px',
        form: [
          {
            name: '理由',
            type: 'textArea',
            key: 'content',
          },
        ],
        success: ({ data, setHidden }) => {
          if (data.content) {
            let params = {
              refundId: records.id,
              handleResult: '4',
              handleNote: data.content,
            }
            let url = '/order/refund/ticketRefund'
            if (['12'].includes(records.formType)) {
              url = '/order/refund/roomRefund'
            }
            if (['14'].includes(records.formType)) {
              url = '/order/refund/comboRefund'
            }
            putAction(url, {
              ...params,
            }).then((result) => {
              if (result.code == 200) {
                if (result.data != 5) {
                  this.$message.success('操作成功')
                } else {
                  this.$message.error('操作失败')
                }
                this.getDataInit()
                setHidden()
              } else {
                this.$message.error(result.msg)
              }
            })
          } else {
            this.$message.warning('请填写拒绝理由！')
          }
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '返回',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
    // 批量关闭
    onBatchDelete(keys) {
      return api.order.batchClose.call(this, {
        url: '/common/order/closeBatch',
        params: { idList: keys.map((e) => e.id) },
      })
    },
    getButton() {
      return [
        {
          viewType: 'menu',
          name: '更多操作',
          isBatch: true,
          children: [
            {
              name: '批量关闭',
              onClick: this.onBatchDelete,
            },
          ],
        },
      ]
    },
    getTableColumns(data) {
      let columns = [].concat(data.orderItemList)
      if (data.fromType === '14') {
        columns = [].concat(data.comboDetails)
      }
      const arr = [
        {
          name: `V${data.memberLevel}优惠`,
          value: '-￥' + data.memberDiscountAmount,
          display: data.memberDiscountAmount,
        },
        {
          name: '运费',
          value: '￥' + data.freightAmount,
          display: data.freightAmount && this.detail.fromType === '1',
        },
        {
          name: '运费',
          value: '包邮',
          display: !data.freightAmount && this.detail.fromType === '1',
        },
        {
          name: '优惠券',
          //   name: `优惠券${data.couponName}`,
          value: '-￥' + data.couponAmount,
          display: data.couponAmount,
        },
        {
          name: '余额券',
          value: '￥' + data.balanceAmount,
          display: data.balanceAmount,
        },
        {
          name: '实付金额',
          value: '￥' + data.payAmount,
          display: data.payAmount,
        },
      ].filter((e) => e.display)
      arr.forEach((e) => {
        columns.push({
          productQuantity: e.name,
          totalPrice: e.value,
        })
      })
      return columns
    },
    getDrawerForm(data) {
      let _this = this
      let refund = data.refundList.find((e) => e.id == this.refundId)
      const form = [
        {
          display: data.fromType == '14',
          form: [
            {
              type: 'text',
              value: `<div>
        <img style="width: 60px;height: 60px;border-radius: 6px;margin-right:8px" src="${
          data?.orderItemList[0]?.productPic
        }"></img>
          ${data?.orderItemList[0]?.productName || ''}
        </div>`,
            },
          ],
        },
        {
          display: data.fromType == '14',
          class: 'order-table',
          form: [
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: [],
              dataSource: this.getTableColumns(data),
              showPagination: false,
              columns: [
                {
                  title: '酒店/景点名称',
                  dataIndex: 'businessName',
                  type: 'ct-300',
                  display: true,
                },
                {
                  title: '商品名称',
                  dataIndex: 'productName',
                  display: true,
                },
                {
                  title: '原价（元）',
                  dataIndex: 'salePrice',
                  display: true,
                },
                {
                  title: '套餐价(元)',
                  dataIndex: 'salePriceTotal',
                  display: true,
                },
                {
                  title: '总价(元)',
                  display: true,
                  customRender: (text, records, index, h) => {
                    if ((data.comboDetails || []).length > index) {
                      if (index === 0) {
                        records.productQuantity = data.orderItemList[0].productQuantity
                        records.productPrice = data.orderItemList[0].productPrice
                      } else {
                        records.totalPrice = ' '
                      }
                    }
                    if (records.totalPrice) return records.totalPrice
                    return <div>￥ {records.productQuantity * records.productPrice}</div>
                  },
                },
                {
                  title: '已退款',
                  dataIndex: 'refundAmount',
                  display: true,
                  customRender: (text, records, index, h) => {
                    console.log(records)
                    return <div> {text ? '￥' + (records.refundAmount ?? 0) : ''}</div>
                  },
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          display: data.fromType != '14',
          class: 'order-table',
          form: [
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: [],
              dataSource: this.getTableColumns(data),
              showPagination: false,
              columns: [
                {
                  title: '商品',
                  type: 'lt-200',
                  display: true,
                  customRender: (text, records, index, h) => {
                    if (!records.productPic || !records.productName) return null
                    return (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'flex-start',
                        }}
                      >
                        <img
                          src={records.productPic}
                          style={{
                            width: '30px',
                            height: '30px',
                            marginRight: '10px',
                          }}
                        />
                        <div
                          style={{
                            maxWidth: '260px',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                          }}
                        >
                          {/* {records.productName.length > 10
                      ? records.productName.substring(0, 10) + '……'
                      : records.productName} */}
                          {records.productName}
                          <br />
                          {refund.productAttr ? records.productAttr : ''}
                        </div>
                      </div>
                    )
                  },
                },
                // {
                //   title: '规格',
                //   dataIndex: 'productAttr',
                //   type: 'ct-100',
                //   display: refund.productAttr
                // },
                {
                  title: '数量',
                  dataIndex: 'productQuantity',
                  type: 'ct-100',
                  display: true,
                },
                {
                  title: '总价(元)',
                  display: true,
                  customRender: (text, records, index, h) => {
                    if (records.totalPrice) return records.totalPrice
                    return <div>￥ {records.productQuantity * records.productPrice}</div>
                  },
                },
                {
                  title: '已退款',
                  dataIndex: 'refundAmount',
                  display: true,
                  customRender: (text, records, index, h) => {
                    console.log(records)
                    return <div> {text ? '￥' + (records.refundAmount ?? 0) : ''}</div>
                  },
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          display: data.fromType == '1',
          title: '配送',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '配送方式',
                  value: { 0: '快递配送', 1: '自行配送' }[data.deliveryType] || '',
                  display: true,
                },
                {
                  name: '收件人',
                  value: data.receiveMan,
                  display: data.receiveMan,
                },
                {
                  name: '快递单号',
                  value: data.deliverySn,
                  display: data.deliverySn,
                },
                {
                  name: '收货人电话',
                  value: data.receiverPhone,
                  display: data.receiverPhone,
                },
                {
                  name: '收货地址',
                  width: '100%',
                  display: data.receiverProvince,
                  value:
                    (data.receiverProvince || '') +
                    ' ' +
                    (data.receiverCity || '') +
                    ' ' +
                    (data.receiverRegion || '') +
                    ' ' +
                    (data.receiverDetailAddress || ''),
                },
                {
                  name: '订单备注',
                  value: data.remark,
                  display: data.remark,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        ..._this.coupons(data),
        {
          // 景点门票/农家乐/农事体验订单
          display: ['14', '12', '2', '4'].includes(data.fromType),
          class: 'order-table',
          form: [
            {
              type: 'table',
              wrapperCol: 24,
              dataSource: data.userCodeVOList,
              showRowSelect: false,
              showPagination: false,
              rowKey: 'code',
              columns: [
                {
                  title: '核销码',
                  type: 'lt-200',
                  display: true,
                  customRender: (text, records, index, h) => {
                    return <div>核销码: {records.code}</div>
                  },
                },
                {
                  title: '分销商',
                  dataIndex: 'thirdPlatform',
                  type: 'ct-100',
                  display: true,
                  customRender: (text, records, index, h) => {
                    return { 0: '自我游', 1: '云上游' }[records.thirdPlatform] || ''
                  },
                },
                {
                  title: '状态',
                  dataIndex: 'status',
                  type: 'ct-100',
                  display: true,
                  customRender: (text, records, index, h) => {
                    return { 0: '未使用', 1: '已使用', 2: '已退款', 3: '退款中' }[records.status] || ''
                  },
                },
                {
                  title: '时间',
                  display: true,
                  dataIndex: 'createTime',
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          display: true,
          title: '退款信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '货物状态',
                  value: ['已收到货', '未收到货'][Number(refund.receiveStatus) - 1],
                  display: ['1'].includes(data.fromType) && refund.receiveStatus,
                },
                {
                  name: '申请时间',
                  value: refund.createTime,
                  display: refund.createTime,
                },
                {
                  name: '退款金额',
                  value: refund.returnAmount,
                  display: refund.returnAmount,
                },
                {
                  name: '退款原因',
                  width: '100%',
                  value: refund.reason,
                  display: refund.reason,
                },
                {
                  name: '更多原因',
                  width: '100%',
                  value: refund.description,
                  display: refund.description,
                },
              ].filter((e) => e.display),
            },
            {
              type: 'imageGroup',
              value: refund.proofPics,
            },
          ],
        },
        {
          display: !['13', '14'].includes(data.fromType),
          title: '基础信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '店铺名称',
                  value: data.shopName,
                  display: data.shopName,
                },
                {
                  name: '用户姓名',
                  value: data.buyName,
                  display: data.buyName,
                },
                {
                  name: '核销截止时间',
                  value: data?.orderItemList[0]?.effectiveEndTime,
                  display: data?.orderItemList[0]?.effectiveEndTime,
                },
                {
                  name: '订单编号',
                  value: data.orderSn,
                  display: data.orderSn,
                },
                {
                  name: '登录手机',
                  value: data.buyPhone,
                  display: data.buyPhone,
                },
                {
                  name: '订单状态',
                  value: ['待付款', '待使用', '待发货', '待签收', '待评价', '已完成', '已关闭'][Number(data.status)],
                  display: ['1'].includes(data.fromType) && data.status,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          display: ['13', '14'].includes(data.fromType),
          title: '基础信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '订单编号',
                  value: data.orderSn,
                  display: data.orderSn,
                },
                {
                  name: '订单状态',
                  display: data.status,
                  value: data.refundId
                    ? '待退款'
                    : (orderStateList2.find((e) => e.value == data.status) || { text: '' }).text,
                },
                {
                  name: '店铺名称',
                  value: data.shopName,
                  display: data.shopName,
                },
                {
                  name: '入住时间',
                  value: data.orderItemList ? moment(data.orderItemList[0].checkInTime).format('YYYY年MM月DD日') : '',
                  display: data.orderItemList ? data.orderItemList[0].checkInTime : false,
                },
                {
                  name: '用户姓名',
                  value: data.buyName,
                  display: data.buyName,
                },
                {
                  name: '离店时间',
                  value: data.orderItemList ? moment(data.orderItemList[0].leaveTime).format('YYYY年MM月DD日') : '',
                  display: data.orderItemList ? data.orderItemList[0].leaveTime : false,
                },
                {
                  name: '手机号',
                  display: data.buyPhone,
                  value: data.buyPhone,
                },
                {
                  name: '备注',
                  display: data.remark,
                  value: data.remark,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        ..._this.refundListData(data),
        {
          display: data.fromType == '1',
          title: '物流信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '配送方式',
                  value: { 0: '快递配送', 1: '自行配送' }[data.deliveryType] || '',
                  display: true,
                },
                {
                  name: '快递公司',
                  value: data.deliveryCompany,
                  display: data.deliveryType == '0',
                },
                {
                  name: '快递单号',
                  value: data.deliverySn,
                  display: data.deliveryType == '0',
                },
              ].filter((e) => e.display),
            },
            {
              type: 'timeline',
              typeData: (data.logisticsList || []).map((e) => {
                return {
                  name: e.acceptStation,
                  value: e.acceptTime,
                }
              }),
            },
          ],
        },
        {
          // 景点门票订单
          display: data.fromType == '12',
          class: 'order-table',
          form: [
            {
              display: true,
              type: 'table',
              wrapperCol: 24,
              dataSource: data.visitorList,
              showPagination: false,
              columns: [
                {
                  title: '姓名',
                  dataIndex: 'concatName',
                  display: true,
                },
                {
                  title: '手机号',
                  dataIndex: 'concatPhone',
                  display: true,
                },
                {
                  title: '时间',
                  display: true,
                  dataIndex: 'createTime',
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          // 酒店民宿订单
          display: ['13', '14'].includes(data.fromType),
          class: 'order-table',
          form: [
            {
              display: true,
              type: 'table',
              wrapperCol: 24,
              dataSource: data.visitorList,
              showPagination: false,
              columns: [
                {
                  title: '姓名',
                  dataIndex: 'concatName',
                  display: true,
                },
                {
                  title: '手机号',
                  dataIndex: 'concatPhone',
                  display: true,
                },
                {
                  title: '身份证',
                  display: true,
                  dataIndex: 'concatIdCard',
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          // 景点门票订单
          display: data.thirdTicket == '1',
          title: '第三方票务',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '票务平台',
                  value: { 0: '自我游', 1: '云上游' }[data.thirdPlatform],
                  display: data.thirdPlatform,
                },
                {
                  name: '推送状态',
                  value: { 0: '未推送', 1: '推送成功', 2: '推送失败' }[data.pushStatus],
                  display: data.thirdOrderNo && data.pushStatus,
                },
                {
                  name: '三方订单号',
                  value: data.thirdOrderNo,
                  display: data.thirdOrderNo,
                },
                {
                  name: '门票id',
                  value: data.orderSn,
                  display: data.orderSn,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          display: true,
          title: '记录',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '下单时间',
                  value: data.createTime,
                  display: data.createTime,
                },
                {
                  name: '支付时间',
                  value: data.paymentTime,
                  display: data.paymentTime,
                },
                {
                  name: '发货时间',
                  value: data.deliveryTime,
                  display: data.deliveryTime,
                },
                {
                  name: '完成时间',
                  value: data.updateTime,
                  display: data.updateTime,
                },
                {
                  name: '退款时间',
                  value: data.refundTime,
                  display: data.refundTime,
                },
              ].filter((e) => e.display),
            },
          ],
        },
        {
          // 景点门票订单
          display: data.fromType == '12' && refund.handleNote,
          title: '拒绝详情',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '拒绝理由',
                  value: refund.handleNote,
                  display: refund.handleNote,
                },
              ].filter((e) => e.display),
            },
          ],
        },
      ].filter((e) => e.display)

      return form
    },
    coupons(data) {
      return (data.userCodeVOList || []).map((e, i) => {
        return {
          title: '券' + (i + 1),
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '券状态',
                  value: ['未使用', '已使用', '已退款'][e.status],
                  display: true,
                },
                {
                  name: '时间',
                  value: e.usedTime,
                  display: e.usedTime,
                },
                {
                  name: '退款原因',
                  value: e.reason,
                  width: '100%',
                  display: e.reason,
                },
                {
                  name: '更多原因',
                  value: e.description,
                  width: '100%',
                  display: e.description,
                },
              ].filter((e) => e.display),
            },
          ],
        }
      })
    },
    refundListData(data) {
      //   console.log(data.refundList, 'refundList')
      if (this.refundStatue != 1) {
        return (data.refundList || []).map((e, i) => {
          return {
            title: '售后处理',
            form: [
              {
                type: 'textGroup',
                value: [
                  {
                    name: '问题类型',
                    value: ['仅退款', '退货退款'][Number(e.returnType)],
                  },
                  {
                    name: '退款金额',
                    value: e.returnAmount,
                    props: {
                      addonBefore: '￥',
                    },
                  },
                  {
                    name: '问题描述',
                    width: '100%',
                    type: 'textArea',
                    value: e.handleNote,
                  },
                ],
              },
            ],
          }
        })
      } else {
        return []
      }
    },
    getRefundDrawerForm(data, clickState, formData) {
      let refund = data.refundList.find((e) => e.id == this.refundId)
      // let refund = data.refundList.find(e => e.status == 1 || e.status == 3) || {}
      formData.returnCount = formData.returnCount ? formData.returnCount : refund.productCount ? refund.productCount : 1

      formData.returnAmount = formData.returnAmount
        ? formData.returnAmount
        : refund.productCount * refund.productRealPrice

      const form = [
        {
          display: true,
          title: '购买方信息',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '收货人',
                  value: data.buyName,
                },
                {
                  name: '收货人电话',
                  value: data.buyPhone,
                },
                {
                  name: '实付金额',
                  value: data.payAmount,
                },
              ],
            },
          ],
        },
        {
          display: clickState == '退款处理',
          title: '退款详情',
          form: [
            {
              type: 'textGroup',
              value: [
                {
                  name: '货物状态',
                  value: ['已收到货', '未收到货'][Number(refund.receiveStatus) - 1],
                },
                {
                  name: '退款金额',
                  value: refund.returnAmount,
                },
                {
                  name: '退款原因',
                  width: '100%',
                  value: refund.reason,
                },
                {
                  name: '反馈问题',
                  width: '100%',
                  value: refund.description,
                },
              ],
            },
            {
              type: 'imageGroup',
              value: refund.proofPics,
            },
          ],
        },
        {
          display: true,
          title: '售后处理信息',
          form: [
            {
              name: '处理结果',
              key: 'handleResult',
              type: 'radioButton',
              display: true,
              defaultValue: '0',
              labelAlign: 'left',
              typeData: [
                {
                  name: '同意退款',
                  value: '0',
                },
                {
                  name: '退款退货',
                  value: '1',
                },
                {
                  name: '供应商补发',
                  value: '2',
                },
                {
                  name: '已经处理',
                  value: '3',
                },
                {
                  name: '驳回退款',
                  value: '4',
                },
              ],
            },
            {
              type: 'inputNumber',
              name: '商品数量',
              key: 'returnCount',
              labelAlign: 'left',
              display: formData.handleResult == 0 || formData.handleResult == 1,
              props: {
                min: 1,
                max: refund.productCount,
              },
            },
            {
              type: 'inputNumber',
              name: '退款金额',
              key: 'returnAmount',
              labelAlign: 'left',
              props: {
                min: 0,
                // max: formData.returnAmount
              },
              display: formData.handleResult == 0 || formData.handleResult == 1,
            },
            // {
            //   type: 'input',
            //   name: '退款金额',
            //   key: 'returnAmount',
            //   props: {
            //     addonBefore: '￥'
            //   },
            //   display: formData.handleResult == 0 || formData.handleResult == 1
            // },
            {
              display: true,
              name: '问题描述',
              type: 'textArea',
              labelAlign: 'left',
              key: 'handleNote',
            },
          ].filter((e) => e.display),
        },
      ].filter((e) => e.display)
      return form
    },
    onRowSelect(records, clickState = null) {
      this.refundStatue = records.status
      switch (records.formType) {
        case '1':
          this.getDataOrderRefund(records, 'product/order', 'id', clickState)
          break
        case '2':
          this.getDataOrderRefund(records, 'agr/order', 'id', clickState)
          break
        case '4':
          this.getDataOrderRefund(records, 'farming/order', 'id', clickState)
          break
        case '12':
          this.getDataOrderRefund(records, 'ticketOrder', 'orderId', clickState)
          break
        case '13':
          this.getDataOrderRefund(records, 'room/order', 'id', clickState)
          break
        case '14':
          this.getDataOrderRefund(records, 'combo/order', 'id', clickState)
          break
      }
    },
    getDataOrderRefund(records, str, idName = 'id', clickState = null) {
      this.orderRefundId = records.orderId
      this.orderItemId = records.orderItemId
      this.refundId = records.id
      getAction(`/api/${str}/detail?${idName}=${records.orderId}`).then((result) => {
        if (result.code == 200) {
          let resultData = JSON.parse(JSON.stringify(result.data))
          let refundData = resultData.refundList.find((e) => e.id == this.refundId)

          if (clickState != '退款处理' && clickState != '主动退款') {
            const data = this.getDrawerForm(resultData)
            apiTool.showDrawer({
              title: '订单信息',
              width: '900px',
              view: DrawerForm,
              viewProps: { data },
              foot: ({ close, submit }) => {
                return [
                  {
                    name: '取消',
                    onClick: close,
                  },
                ]
              },
            })
          } else {
            apiTool.showDrawer({
              title: '订单信息',
              width: '900px',
              view: DrawerForm,
              viewProps: {
                formProps: {
                  layout: 'horizontal',
                  labelCol: { span: 3 },
                  wrapperCol: { span: 21 },
                },
                form: refundData,
                data: (formData) => this.getRefundDrawerForm(resultData, clickState, formData),
              },
              success: (data) => {
                this.handleRefundComfig(data, resultData, str)
              },
              foot: ({ close, submit }) => {
                return [
                  {
                    name: '取消',
                    onClick: close,
                  },
                  {
                    name: '确认处理',
                    type: 'primary',
                    onClick: submit,
                  },
                ]
              },
            })
          }
        } else {
          this.$message.error(result.msg)
        }
      })
    },
    handleRefundComfig(dataSource, result, str) {
      const { data, setHidden } = dataSource
      let params = {
        handleResult: data.handleResult,
        handleNote: data.handleNote,
        returnCount: data.returnCount,
        returnAmount: Number(data.returnAmount),
        itemId: this.orderItemId,
        refundId: this.refundId,
      }
      putAction('/order/refund', {
        ...params,
        // ...data,
        // returnAmount: Number(data.returnAmount),
        // orderId: this.orderRefundId,
        // itemId: this.orderItemId,
        // refundId: this.refundId
      }).then((result) => {
        if (result.code == 200) {
          if (result.data != 5) {
            this.$message.success('操作成功')
          } else {
            this.$message.error('操作失败')
          }
          setHidden()
          this.getDataInit()
        } else {
          this.$message.error(result.msg)
        }
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        ref={'tableRef'}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
        onSelectRow={this.onRowSelect}
      />
    )
  },
}
</script>

<style lang="less">
.product-order-item {
  display: flex;
  align-items: flex-start;
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
    border-radius: 5px;
    object-fit: cover;
  }
}
.product-number {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  div {
    height: 30px;
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
}
</style>
